/* eslint-disable */
import { BiShoppingBag, BiPencil, BiBookContent } from "react-icons/bi";
import { RiChatVoiceLine } from "react-icons/ri";
import { GiArtificialIntelligence } from "react-icons/gi";
import { CgTranscript } from "react-icons/cg";
import { AiOutlineMail, AiOutlineTranslation } from "react-icons/ai";
import { FaRegCopyright,  FaChalkboardTeacher, FaLanguage } from "react-icons/fa";



export const servicesData = [
    {
        id: 1,
        title: 'Translation projects',
        icon: <AiOutlineTranslation />
    },
    {
        id: 2,
        title: 'Proofreading',
        icon: <BiBookContent />
    },
    {
        id: 3,
        title: 'Machine Translation & Post-Editing',
        icon: <GiArtificialIntelligence />
    },
    {
        id: 4,
        title: 'Spanish/English classes',
        icon: <FaChalkboardTeacher />
    },
    {
        id: 5,
        title: 'Media Transcription',
        icon: <CgTranscript />
    },
    {
        id: 6,
        title: 'Copywriting / Transcreation',
        icon: <FaRegCopyright />
    },
    {
        id: 7,
        title: 'Content Writing',
        icon: <BiPencil />
    },
]

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.
