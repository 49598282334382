export const educationData = [
    {
        id: 1,
        institution: 'ISTRAD',
        course: 'Master in Audiovisual Translation (DE>ES)',
        startYear: '2022',
        endYear: '2024',
    },
    {
        id: 2,
        institution: 'ISTRAD',
        course: 'Master in Specialised Biomedical Translation (DE>ES)',
        startYear: '2022',
        endYear: '2023',
    },
    {
        id: 3,
        institution: 'Trágora Formación',
        course: 'Specialist course in medical translation (EN>ES)',
        startYear: 'Feb. 2022',
        endYear: 'June 2022'
    },
    {
        id: 4,
        institution: 'Universidad Pablo de Olavide, Seville - Spain',
        course: 'Bachelor degree - Translation and Interpreting',
        startYear: '2016',
        endYear: '2020'
    },
    {
        id: 5,
        institution: 'Universität Regensburg, Regensburg - Germany',
        course: 'Erasmus exchange year',
        startYear: '2017',
        endYear: '2018'
    },
]
