import resume from '../assets/pdf/resume_EN.pdf'
import profileImg from '../assets/png/profileImg.png'

export const headerData = {
    name: 'Maria Carmona Morales',
    title: 'Translator - Audiovisual Translator and Project Manager',
    quote: 'Machine translation will displace only those humans who translate like machines',
    author: 'Arle Richard Lommel',
    desciption:'',
    image: profileImg,
    resumePdf: resume
}
