export const experienceData = [
  {
    id: 1,
      company: 'Kern AG, Sprachendienst',
      jobtitle: 'Project manager',
      details: 'Main tasks: First as an intern, then as a worker: organizing and coordinating projects, acting as the main point of contact for customers and suppliers, preparing procurement and tenders, being involved in the on-boarding of new clients and employees, and the quality management of the company.',
      startYear: 'Sept. 2023',
      endYear: 'July 2024',
  },
  {
      id: 2,
      company: 'Más Traducciones',
      jobtitle: 'Internship: Audiovisual Translation',
      details: 'Main tasks: Translating several TV show episodes from English for their future dubbing in Spanish for various platforms such as Netflix or HBO. Highlights: Love is blind (season 6), Inside Sydney Airport (season 1), World War II: From the Frontlines.',
      startYear: 'Sept. 2023',
      endYear: 'Dec. 2023',
  },
  {
      id: 3,
      company: 'Festival de Cine Africano (FCAT)',
      jobtitle: 'Translator EN>ES',
      details: 'Main tasks: translating subtitles for the 20th edition of FCAT into Spanish. Movies: "Museu de Manifestações" and "Azul Luanda".',
      startYear: 'May 2023', 
  },
  {     
      id: 4,
      company: 'TU Dortmund Universität',
      jobtitle: 'Translator DE>EN',
      details: 'Main tasks: translating the university´s registration website from German into English, covering degree program details, subjects and their descripion.',
      startYear: 'Oct. 2022',
      endYear: 'May 2023',
  },
  {
      id: 5,
      company: 'Robert-Schuman-Berufskolleg',
      jobtitle: 'Spanish Language assistant',
      details: 'Main tasks: helping with the teaching of Spanish, collaborating in the preparation of training materials, bringing students closer to the Spanish culture and enable them to speak the language.',
      startYear: 'Sept. 2020',
      endYear: 'June 2022'
  },
  {
      id: 6,
      company: 'Fincallorca GmbH',
      jobtitle: 'Proofreader DE>EN/ES',
      details: 'Main tasks: revising translated descriptions (made by an automatic translator) of the fincas offered by this company in Mallorca from German into Spanish and improving the style for them to sound natural.',
      startYear: 'July 2021',
      endYear: 'Sept. 2021'
  },
  {
      id: 7,
      company: 'Universität Bielefeld',
      jobtitle: 'ERASMUS+ Internship',
      details: 'Main tasks: creating and developing exams, helping in the classroom, answering questions and preparing and giving Spanish classes.',
      startYear: 'Apr. 2021',
      endYear: 'July 2021'
  },
  {
      id: 8,
      company: 'Universidad Pablo de Olavide - Florida International University',
      jobtitle: 'Internship: Translator and proofreader EN<>ES',
      details: 'Main tasks: ES>EN translation of research made by the Sociology Department and EN>ES proofreading of the project "Effects of Childhood Abuse and Mental Health on Emotion and Health-Risk Behaviors Among University".',
      startYear: 'Feb. 2020',
      endYear: 'May 2020'
  },
]
