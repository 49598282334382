import bdu from '../assets/svg/projects/bdue.svg'
import fiu from '../assets/svg/projects/FIU.svg'
import upo from '../assets/svg/projects/UPO.svg'
import jm from '../assets/svg/projects/JM.svg'
import tf from '../assets/svg/projects/TF.png'
import md from '../assets/svg/projects/MD.svg'

import bdu_10_18 from '../assets/pdf/bdu_10-2018.pdf'
import fiu_05_20 from '../assets/pdf/fiu_05-2020.pdf'
import upo_06_20 from '../assets/pdf/upo_06-2020.pdf'
import upo_07_20 from '../assets/pdf/upo_07-2020.pdf'
import jm_02_21  from '../assets/pdf/jm_02-2021.pdf'
import jm_02_21_ from '../assets/pdf/jm_02-2021_.pdf'
import jm_10_21  from '../assets/pdf/jm_10-2021.pdf'
import jm_11_21  from '../assets/pdf/jm_11-2021.pdf'
import jm_02_22  from '../assets/pdf/jm_02-2022.pdf'
import jm_03_22  from '../assets/pdf/jm_03-2022.pdf'
import jm_03_22_ from '../assets/pdf/jm_03-2022_.pdf'
import jm_04_22  from '../assets/pdf/jm_04-2022.pdf'
import jm_06_22_ from '../assets/pdf/jm_06-2022_.pdf'
import jm_06_22  from '../assets/pdf/jm_06-2022.pdf'
import jm_10_22  from '../assets/pdf/jm_10-2022.pdf'
import jm_11_22  from '../assets/pdf/jm_11-2022.pdf'
import jm_07_22  from '../assets/pdf/jm_07-2022.pdf'

import tf_06_22  from '../assets/pdf/tf_06-2022.pdf'
import md_07_22  from '../assets/pdf/md_07-2022.pdf'

export const projectsData = {
    bio : "I am constantly striving to expand my skillset and stay up-to-date with the latest trends. Below are some of the certifications I have earned in recent years, demonstrating my commitment to ongoing learning and professional development.",

    projects : [
      {
          id: 1,
          projectName: 'Specialised Medical translation',
          projectDesc: 'Translation strategies - The medical translation job market',
          tags: ['Medical terminology', 'Glossaries'],
          doc: tf_06_22,
          link: 'https://www.tragoraformacion.com/cursos/traduccion/curso-traduccion-medica/',
          image: tf
      },
      {
          id: 2,
          projectName: 'Medical Education',
          projectDesc: 'Medical Education: Necessary in the education of medicine',
          tags: ['Medical education', 'Medical teaching'],
          doc: md_07_22,
          link: 'https://juan-macarlupu.verified.cv/en/verify/56980376249360',
          image: md
      },
      {
          id: 3,
          projectName: 'Inverted Translation / Proofreading',
          projectDesc: 'Effects of Childhood Abuse and Mental Health on Emotion and Health-Risk Behaviors Among University Students.',
          tags: ['Proofreading', 'Research', 'International'],
          doc: fiu_05_20,
          link: 'https://www.fiu.edu/',
          image: fiu
      },
      {
          id: 4,
          projectName: 'Informal Medical Language',
          projectDesc: 'Lenguaje médico informal, jerga médica y eufemismos',
          tags: ['Medical slang', 'Euphemisms', 'Argot'],
          doc: jm_11_22,
          link: 'https://juanmacarlupu.com/certificado/103661',
          image: jm
      },
      {
          id: 5,
          projectName: 'Ressources in Medical Translation',
          projectDesc: 'El uso de los recursos en línea para la traducción médica - recursos y sugerencias para traductores médicos',
          tags: ['Research', 'Resources', 'Documentation'],
          doc: jm_10_22,
          link: 'https://juanmacarlupu.com/certificado/101610',
          image: jm
      },
      {
          id: 6,
          projectName: 'Complex Medical Terminology',
          projectDesc: 'Los 15 términos más difíciles de traducir en traducción médica moderna',
          tags: ['Complex terminology', 'Translation'],
          doc: jm_07_22,
          link: 'https://juanmacarlupu.com/certificado/95248',
          image: jm
      },
      {
          id: 7,
          projectName: 'Transcreation',
          projectDesc: 'El qué, quién, cómo, cuándo, dónde y por qué de la transcreación',
          tags: ['Transcreation', 'Marketing', 'Business'],
          doc: jm_06_22_,
          link: 'https://juanmacarlupu.com/certificado/92817',
          image: jm
      },
      {
          id: 8,
          projectName: 'Medical Postediting',
          projectDesc: 'El traductor médico como poseditor: desafíos y fortalezas de una profesión que no elegimos',
          tags: ['Postediting', 'Proofreading', 'Spelling'],
          doc: jm_06_22,
          link: 'https://juanmacarlupu.com/certificado/91729',
          image: jm
      },
      {
          id: 9,
          projectName: 'Morphology and Grammar',
          projectDesc: 'Morfologueando: Algunos problemas y otras tantas soluciones en torno del género gramatical en español',
          tags: ['Grammar', 'Morphology', 'Syntax'],
          doc: jm_04_22,
          link: 'https://juanmacarlupu.com/certificado/87290',
          image: jm
      },
      {
          id: 10,
          projectName: 'Translation Problem Analysis',
          projectDesc: 'Cómo reconocer un problema de traducción. El enemigo oculto',
          tags: ['False friends', 'Cultural problems', 'Lexic'],
          doc: jm_03_22_,
          link: 'https://juan-macarlupu.verified.cv/en/verify/84445204949874',
          image: jm
      },
      {
          id: 11,
          projectName: 'Audiovisual translation for kids',
          projectDesc: 'Traducción audiovisual de contenido infantil y juvenil',
          tags: ['Subtitles', 'Dubbing', 'Kids'],
          doc: jm_03_22,
          link: 'https://juan-macarlupu.verified.cv/en/verify/56980376249360',
          image: jm
      },
      {
          id: 12,
          projectName: 'Inclusive language',
          projectDesc: '¿De qué hablamos cuando hablamos de traducción queer?',
          tags: ['Queer', 'Inclusive language'],
          doc: jm_02_22,
          link: 'https://juan-macarlupu.verified.cv/en/verify/23990245531206',
          image: jm
      },
      {
          id: 13,
          projectName: 'Medical terminology',
          projectDesc: 'La terminología médica como recurso de formación para el traductor especializado',
          tags: ['Ethymology', 'Semantics', 'Acronyms '],
          doc: jm_11_21,
          link: 'https://juan-macarlupu.verified.cv/en/verify/23100291564108',
          image: jm
      },
      {
          id: 14,
          projectName: 'Medical translation',
          projectDesc: 'Jornadas de Medicina para Traductores e Intérpretes 5',
          tags: ['Medical terminology', 'Anatomy', 'Biology'],
          doc: jm_10_21,
          link: 'https://juan-macarlupu.verified.cv/en/verify/85211402910635',
          image: jm
      },
      {
          id: 15,
          projectName: 'Technical translation',
          projectDesc: '15 Términos Engañosos de la Traducción Técnica',
          tags: ['Technical terminology'],
          doc: jm_02_21,
          link: jm_02_21,
          image: jm
      },
      {
          id: 16,
          projectName: 'Business',
          projectDesc: 'Managing Business Terms',
          tags: ['Business terminology', 'False friends'],
          doc: jm_02_21_,
          link: jm_02_21_,
          image: jm
      },
      {
          id: 17,
          projectName: 'Competencias Profesionales',
          projectDesc: 'Curso online de Competencias Profesionales para el Empleo.',
          tags: ['Job market', 'Analysis', 'Professional skills'],
          doc: upo_07_20,
          link: 'https://www.upo.es/portal/impe/web/portada/index.html',
          image: upo
      },
      {
          id: 18,
          projectName: 'Competencia digital',
          projectDesc: 'Competencia digital para estudiantes de Grado. Nivel Avanzado (Trabajo Fin de Grado).',
          tags: ['Thesis', 'Research', 'Documentation'],
          doc: upo_06_20,
          link: 'https://www.upo.es/portal/impe/web/portada/index.html',
          image: upo
      },
      {
        id: 19,
        projectName: 'BDÜ e.V. - die qualifizierten Dolmetscher und Übersetzer',
        projectDesc: 'Seminar: Spanisches Recht lernen, erleben, verstehen 2018 - Erbrecht und Reformen des Straf- und Zivilrechts',
        tags: ['Deutsch', 'Exchange', 'Culture'],
        doc: bdu_10_18,
        link: 'https://bdue.de/der-bdue',
        image: bdu
    },
]}


// Do not remove any fields.
// Leave it blank instead as shown below

/*
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    doc: '',
    link: '',
    image: ''
},
*/
